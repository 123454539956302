import React, { Component } from "react"
import styled from "styled-components"
import { colours } from "../style/colours"
import LineItem from "./cart-flyout/line-item"
import { connect } from "react-redux"
import { toggleShowCart, updateCartCount } from "../state/app"
import { MdClose } from "react-icons/md"
import { navigate } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"

const variants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 1, y: "+100%" },
}

const Container = styled(motion.div)`
  padding: 60px 20px;
  position: fixed;
  top: 0;
  top: initial;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 90vh;
  background: white;
  z-index: 100;
  transition: 0.2s ease;
  overflow-y: scroll;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-top: 1px solid #cccccc;
`
const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.h2`
  color: ${colours.maroonDark};
  margin: 0;
  margin-bottom: 30px;
`
const CloseBtn = styled.button`
  background: none;
  border: none;
  outline: none;
  align-items: center;
  cursor: pointer;
  margin-bottom: 30px;
  color: red;
`
const CartContainer = styled.section`
  max-height: 400px;
  overflow-y: scroll;
  margin-bottom: 10px;
`
const EmptyText = styled.p`
  text-align: center;
  margin: 0;
  opacity: 0.8;
  font-size: 20px;
`
const Break = styled.hr`
  opacity: 0.3;
`
const TotalCostContainer = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
  align-items: center;
`
const Subtotal = styled.p`
  font-weight: 600;
  font-size: 25px;
  opacity: 0.9;
  margin: 0;
`
const LineTitle = styled.h4`
  margin: 0;
`
const CheckoutBtn = styled.button`
  background: ${colours.greenDark};
  color: white;
  padding: 10px;
  width: 100%;
  text-transform: uppercase;
  font-size: 15px;
  border: none;
  margin-top: 10px;
  cursor: pointer;
`
class MobileCartFlyout extends Component {
  state = {}

  componentDidMount() {
    const { shoppingCart } = this.props
    
    if (!shoppingCart.length) {
      
    }
  }

  renderPrice = () => {
    const { shoppingCart } = this.props
    // console.log(shoppingCart)

    const priceAndCount = shoppingCart.map(item => ({
      price: item.priceRange.minVariantPrice.amount,
      count: item.count,
    }))

    const totals = priceAndCount.map(item => item.count * item.price)

    const reducer = (accumulator, currentValue) => accumulator + currentValue

    const totalPrice = totals.reduce(reducer)
    return totalPrice.toFixed(2)
  }

  handleRemoveItem = product => {
    const { shoppingCart, dispatch } = this.props

    //Find the element to remove
    const filteredCart = shoppingCart.filter(
      item => item.shopifyId !== product.shopifyId
    )

    //Push the new cart to global state
    dispatch(updateCartCount(filteredCart))
  }

  handleAddToCount = product => {
    const { shoppingCart, dispatch } = this.props
    let productsToReturn = shoppingCart

    //Find the product in cart
    const obj = shoppingCart.find(el => el.shopifyId === product.shopifyId)

    if (obj) {
      //find the index and adjust the count
      let index = shoppingCart.indexOf(obj)
      productsToReturn[index].count = productsToReturn[index].count + 1

      //Dispatch new cart with updated counts to state
      dispatch(updateCartCount(productsToReturn))
    }
  }

  handleMinusFromCount = product => {
    const { shoppingCart, dispatch } = this.props
    let productsToReturn = shoppingCart

    //Find the product in cart
    const obj = shoppingCart.find(el => el.shopifyId === product.shopifyId)

    if (obj) {
      //find the index and adjust the count
      let index = shoppingCart.indexOf(obj)

      if (productsToReturn[index].count === 1) {
        //Cannot go less than one, they need to remove the product
        return
      }

      productsToReturn[index].count = productsToReturn[index].count - 1

      //Dispatch new cart with updated counts to state
      dispatch(updateCartCount(productsToReturn))
    }
  }

  addCheckoutToSessionStorage = (checkoutObj) => {
    if (typeof window !== 'undefined') {
        // do your checks; this will be re-invoked on client entry
        sessionStorage.setItem('checkout', JSON.stringify(checkoutObj));
    }
  }


  goToCheckout = () => {
    const { shoppingCart } = this.props
    this.addCheckoutToSessionStorage(shoppingCart)

    navigate('/checkout')
  }

  render() {
    const { shoppingCart, isShowingCart, dispatch } = this.props

    return (
      <Container
        animate={isShowingCart ? "open" : "closed"}
        variants={variants}
        initial={{ y: "100%" }}
        visible={isShowingCart}
      >
        <Heading>
          <Title>My Cart</Title>
          <CloseBtn onClick={() => dispatch(toggleShowCart(!isShowingCart))}>
            <MdClose /> Close Cart
          </CloseBtn>
        </Heading>
        {shoppingCart.length > 0 ? (
          <>
            <CartContainer>
              <AnimatePresence>
                {shoppingCart.map((item, index) => (
                  <motion.div
                    exit={{ opacity: 0, x: "100%" }}
                    key={item.shopifyId}
                    positionTransition
                  >
                    <LineItem
                      key={item.shopifyId}
                      product={item}
                      removeItem={this.handleRemoveItem}
                      addToCount={this.handleAddToCount}
                      minusFromCount={this.handleMinusFromCount}
                    />
                    <Break />
                  </motion.div>
                ))}
              </AnimatePresence>
            </CartContainer>

            <TotalCostContainer key={shoppingCart}>
              <LineTitle>Subtotal</LineTitle>
              <Subtotal>${this.renderPrice()}TTD</Subtotal>
            </TotalCostContainer>
            <CheckoutBtn onClick={() => this.goToCheckout()}>
              Checkout
            </CheckoutBtn>
          </>
        ) : (
          <EmptyText>Your cart is empty.</EmptyText>
        )}
      </Container>
    )
  }
}

export default connect(
  state => ({
    isShowingCart: state.app.isShowingCart,
    shoppingCart: state.app.shoppingCart,
  }),
  null
)(MobileCartFlyout)
