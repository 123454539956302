import React from "react"
import styled from "styled-components"
import { colours } from "../../style/colours"

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => (props.display ? "space-between" : "flex-start")};
  width: 100%;
  margin-bottom: 15px;
`
const Img = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 0 10px 0 0;
`
const Name = styled.h4`
  margin: 0;
  text-align: left;
  font-size: 14px;
  width: 200px;
`
const CountComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100px;
`
const CountBtn = styled.button`
  background: none;
  border: none;
  outline: none;
  font-size: 28px;
  font-family: "Muli";
  font-weight: 600;
  color: ${colours.peach};
  cursor: pointer;
`
const CountBox = styled.p`
  margin: 0 5px;
`
const Price = styled.p`
  margin: 0;
  color: ${colours.greenDark};
  font-size: 14px;
  font-weight: 600;
  margin-right: 15px;
`
const RemoveButton = styled.button`
  background: none;
  border: none;
  outline: none;
  color: red;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
`
class LineItem extends React.Component {
  state = {}
  render() {
    const {
      display,
      product,
      removeItem,
      addToCount,
      minusFromCount,
    } = this.props
    return (
      <Container display={display}>
        <Img
          src={product.images[0] ? product.images[0].originalSrc : require('../../images/placeholder.png')}
          // src={product.images[0].originalSrc} 
          />
        <Name>
          {product.count} X {product.title}
        </Name>
        {/* {!display && (
          <CountComponent>
            <CountBtn onClick={() => minusFromCount(product)}>-</CountBtn>
            <CountBox>{product.count}</CountBox>
            <CountBtn onClick={() => addToCount(product)}>+</CountBtn>
          </CountComponent>
        )} */}
        <Price>
          ${product.priceRange.minVariantPrice.amount * product.count}
        </Price>
        {!display && (
          <RemoveButton onClick={() => removeItem(product)}>
            Remove
          </RemoveButton>
        )}
      </Container>
    )
  }
}

export default LineItem
