import React from "react"
import styled from "styled-components"
import HamburgerMenu from "react-hamburger-menu"

import { connect } from "react-redux"
import { toggleShowCart } from "../state/app"
import { IoIosCart } from "react-icons/io"
import { colours } from "../style/colours"
import Menu from "./menu"
import { Link } from "gatsby"

const Nav = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: transparent linear-gradient(180deg, #5c803ee6 0%, #314620e6 100%)
    0% 0% no-repeat padding-box;
  background: ${colours.greenDark};
  z-index: 100;
  padding: 10px 15px;
`
const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const MenuContainer = styled.div``
const Logo = styled.img`
  margin: 0;
  width: 100px;
`
const Cart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const CartButton = styled.div`
  margin: 0;
  color: ${colours.peach};
  text-decoration: none;
  font-family: "Muli";
  cursor: pointer;
  font-weight: 300;
  margin-right: 5px;
`
const CartCount = styled.div`
  color: white;
  background: #cbab81;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Muli";
  font-weight: 400;
  width: 20px;
  width: 20px;
  border-radius: 5px;
`
class MobileNav extends React.PureComponent {
  state = {
    isOpen: false,
  }

  render() {
    const { isShowingCart, shoppingCart, dispatch } = this.props
    return (
      <Nav>
        <InnerContainer>
          <MenuContainer>
            <HamburgerMenu
              isOpen={this.state.isOpen}
              menuClicked={() => this.setState({ isOpen: !this.state.isOpen })}
              width={35}
              height={15}
              strokeWidth={2}
              rotate={0}
              color="white"
              borderRadius={0}
              animationDuration={0.5}
            />
            <Menu isOpen={this.state.isOpen} />
          </MenuContainer>
          <Link to="/">
            <Logo src={require("../images/logo-nav.png")} />
          </Link>
          <Cart>
            <CartButton
              onClick={() => dispatch(toggleShowCart(!isShowingCart))}
            >
              <IoIosCart size={28} />
            </CartButton>
            {shoppingCart.length > 0 ? (
              <CartCount>{shoppingCart.length}</CartCount>
            ) : (
              <CartCount>0</CartCount>
            )}
          </Cart>
        </InnerContainer>
      </Nav>
    )
  }
}

export default connect(
  state => ({
    isShowingCart: state.app.isShowingCart,
    shoppingCart: state.app.shoppingCart,
  }),
  null
)(MobileNav)
