import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { colours } from "../style/colours"
import { connect } from "react-redux"
import { toggleShowCart } from "../state/app"
import { IoIosCart } from "react-icons/io"

const Nav = styled.nav`
  background: transparent linear-gradient(180deg, #5c803ee6 0%, #314620e6 100%)
    0% 0% no-repeat padding-box;
  background: ${colours.greenDark};
  z-index: 100;
  position: sticky;
  top: 0;
  opacity: 0.95;
`
const InnerContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const LogoContainer = styled.div``
const Logo = styled.img`
  height: 60px;
  margin: 0;
`
const Links = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  list-style: none;
  color: white;
`
const Item = styled.li`
  margin: 0;
  color: inherit;
  text-decoration: none;
  margin-left: 20px;
  font-family: "Muli";
  font-weight: 400;
`
const CartButton = styled.li`
  margin: 0 5px 0 0;
  color: ${colours.peach};
  text-decoration: none;
  margin-left: 20px;
  font-family: "Muli";
  cursor: pointer;
  font-weight: 300;
`
const CartCount = styled.div`
  color: white;
  background: #cbab81;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Muli";
  font-weight: 600;
  width: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 5px;
`

class Navbar extends Component {
  state = {}
  render() {
    const { shoppingCart, isShowingCart, dispatch } = this.props
    return (
      <Nav>
        <InnerContainer>
          <LogoContainer>
            <Link to="/">
              <Logo src={require("../images/logo-nav.png")} />
            </Link>
          </LogoContainer>
          <Links>
            {navLinks.map((link, index) => (
              <Link
                key={index}
                style={{ textDecoration: "none", color: "inherit" }}
                activeStyle={{ color: colours.peach, fontWeight: 700 }}
                partiallyActive
                to={link.link}
              >
                <Item>{link.title}</Item>
              </Link>
            ))}
            <CartButton
              onClick={() => dispatch(toggleShowCart(!isShowingCart))}
            >
              <IoIosCart size={32} />
            </CartButton>
            {shoppingCart.length > 0 ? (
              <CartCount
                onClick={() => dispatch(toggleShowCart(!isShowingCart))}
              >
                {shoppingCart.length}
              </CartCount>
            ) : (
              <CartCount
                onClick={() => dispatch(toggleShowCart(!isShowingCart))}
              >
                0
              </CartCount>
            )}
          </Links>
        </InnerContainer>
      </Nav>
    )
  }
}

export default connect(
  state => ({
    isShowingCart: state.app.isShowingCart,
    shoppingCart: state.app.shoppingCart,
  }),
  null
)(Navbar)

const navLinks = [
  {
    title: "Shop",
    link: "/shop",
  },
  // {
  //   title: "Packages",
  //   link: "/packages",
  // },
  {
    title: "On Sale",
    link: "/sale",
  },
  // {
  //   title: "Blog",
  //   link: "/blog",
  // },
]
