import React from "react"
import styled from "styled-components"
import { colours } from "../style/colours"
import { Link } from "gatsby"
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md"
import { FaInstagram, FaCcVisa } from "react-icons/fa"
import { IoIosCash } from "react-icons/io"

const Container = styled.footer`
  background: ${colours.greenDark};
  padding: 20px;
  z-index: 100;
`
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;

  @media (max-width: 780px) {
    flex-direction: column;
  }
`
const Logo = styled.img`
  margin-bottom: 20px;
  height: 100px;
`
const ContactsContainer = styled.div`
  display: flex;
  /* grid-template-columns: 50% 50%; */
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 780px) {
    grid-template-columns: 100%;
  }
`
const ContactItem = styled.p`
  margin: 0;
  color: ${colours.peach};
  color: white;
  margin-right: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;

  @media (max-width: 450px) {
    font-size: 14px;
  }
`
const Anchor = styled.a`
  text-decoration: none;

  :hover {
    text-decoration: underline;
    color: ${colours.peach};
  }
`

const Footer = props => (
  <Container>
    <InnerContainer>
      <Logo src={require("../images/Gradient Wordmark-08.png")} />
      <ContactsContainer>
        <ContactItem>
          <MdLocationOn
            size={20}
            style={{ marginBottom: -2, marginRight: 3 }}
          />{" "}
          No. 29 Saddle Road, Maraval, POS, Trinidad WI
        </ContactItem>
        <Anchor href="mailto: lauralee@gradienthealthfitness.com">
          <ContactItem>
            <MdEmail size={20} style={{ marginBottom: -2, marginRight: 3 }} />{" "}
            lauralee@gradienthealthfitness.com
          </ContactItem>
        </Anchor>
        <Anchor href="tel: 8687923488">
          <ContactItem>
            <MdPhone size={20} style={{ marginBottom: -2, marginRight: 3 }} />{" "}
            868-792-3488
          </ContactItem>
        </Anchor>
        <Anchor href="https://www.instagram.com/gradienthealthfitness/">
          <ContactItem>
            <FaInstagram
              size={20}
              style={{ marginBottom: -2, marginRight: 3 }}
            />{" "}
            @gradienthealthfitness
          </ContactItem>
        </Anchor>
      </ContactsContainer>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <FaCcVisa color="white" size={30} style={{margin: '0 5px'}} />
        <IoIosCash color="white" size={30} style={{margin: '0 5px'}} />

      </div>

    </InnerContainer>
  </Container>
)

export default Footer
