/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import Navbar from "./navbar"
import Footer from "./footer"
import styled from "styled-components"
import CartFlyout from "./cart-flyout"
import MobileCartFlyout from "./mobile-cart-flyout"
import MobileNav from "./mobile-nav"
import MediaQuery from "react-responsive"
if (typeof window !== "undefined") {
  require("pace-progressbar")
  require("pace-progressbar/themes/orange/pace-theme-minimal.css")
}

const Main = styled.main`
  position: relative;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <MediaQuery minDeviceWidth={500}>
        <Navbar />
      </MediaQuery>
      <MediaQuery maxDeviceWidth={500}>
        <MobileNav />
      </MediaQuery>
      <MediaQuery minDeviceWidth={500}>
        <CartFlyout />
      </MediaQuery>
      <MediaQuery maxDeviceWidth={500}>
        <MobileCartFlyout />
      </MediaQuery>
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
